import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import lightbulb from 'assets/icons/lightbulb.svg';
import logoInBee from 'assets/icons/logoInBee.svg';
import logoInBeeSmall from 'assets/icons/logoInBeeSmall.svg';
import logoMaster from 'assets/icons/logoMaster.png';
/* import masterLogo from 'assets/icons/masterLogo.png'; */
import { InspectionMainTab } from 'constants/inspections';
import { INFO_ASSISTANCE_PATHS, STRUCTURES_PATHS } from 'constants/paths';
import { tour_01, tour_paths } from 'constants/tour';
import { useGlobalSelector, useGlobalStore, useTourSelector } from 'stores';
import {
  BtnContainer,
  ElementMenuContainer,
  HamburgerContainer,
  HamburgerIcon,
  Logo,
  LogoContainer,
  SideBarContainer,
} from './Sidebar.style';
import { sidebarMenu, sidebarMenuAdmin } from '../constants';

const Sidebar = ({ isInfoAssistance, userType }) => {
  const { setTourIsOpen } = useTourSelector();
  const { activeTabLevel } = useGlobalSelector();

  const { pathname } = useLocation();

  const [showTourButton, setShowTourButton] = useState(false);

  const navigate = useNavigate();
  /* const { sidebarClose, sidebarVoiceMenu } = useGlobalSelector(); */

  const { sidebarClose, sidebarVoiceMenu, siderAdminVoiceMenu } =
    useGlobalSelector();

  const voiceMenu =
    userType === 'ADMIN' ? siderAdminVoiceMenu : sidebarVoiceMenu;

  const menuElements = userType === 'ADMIN' ? sidebarMenuAdmin : sidebarMenu;

  const toggleHamburger = () => {
    useGlobalStore.setState(prev => ({ sidebarClose: !prev.sidebarClose }));
  };

  //State

  // const [isSelected, setIsSelected] = useState(sidebarVoiceMenu);

  // const onSelected = id => {
  //   setIsSelected(id);
  // };

  useEffect(() => {
    const res = tour_paths.some(el => {
      if (
        (el === STRUCTURES_PATHS.INSPECTION_EDIT ||
          el === STRUCTURES_PATHS.INSPECTION_VIEW) &&
        matchPath({ path: el, exact: true }, pathname)
      ) {
        // https://github.com/nautes-tech/tecnoindagini-issues/issues/535
        // if inside inspection we have to check if we're in L0 or L1
        // to show the tour
        if (
          activeTabLevel === InspectionMainTab.L0_CENSIMENTO_ANAGRAFICA ||
          activeTabLevel === InspectionMainTab.L1_ISPEZIONI_PRELIMINARI
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (matchPath({ path: el, exact: true }, pathname)) {
          return true;
        }
      }
    });
    setShowTourButton(res);
  }, [pathname, activeTabLevel]);

  return (
    <SideBarContainer close={sidebarClose} className={tour_01.step_01}>
      <div>
        <LogoContainer>
          {sidebarClose ? (
            <Logo
              style={{ margin: '30px auto 0', width: '46px', height: '45px' }}
              src={logoInBeeSmall}
            ></Logo>
          ) : (
            <Logo style={{ margin: '30px 30px 0' }} src={logoInBee}></Logo>
          )}

          <HamburgerContainer close={sidebarClose}>
            <HamburgerIcon
              onClick={() => toggleHamburger()}
              close={sidebarClose}
            >
              <span></span>
              <span></span>
              <span></span>
            </HamburgerIcon>
          </HamburgerContainer>
        </LogoContainer>
        {menuElements.map((el, idx) => (
          <ElementMenuContainer
            isInfoAssistance={isInfoAssistance}
            selected={el.id === voiceMenu}
            close={sidebarClose}
            key={idx}
            onClick={() => {
              // onSelected(el.id);
              navigate(el.path && el.path);
            }}
          >
            {isInfoAssistance ? (
              <img src={el.img} />
            ) : voiceMenu === el.id ? (
              <img src={el.imgSelected} />
            ) : (
              <img src={el.img} />
            )}

            {isInfoAssistance ? (
              sidebarClose ? null : (
                <Typography variant="body1">{el.label}</Typography>
              )
            ) : sidebarClose ? null : voiceMenu === el.id ? (
              <Typography style={{ fontWeight: 'bold' }} variant="body1">
                {el.label}
              </Typography>
            ) : (
              <Typography variant="body1">{el.label}</Typography>
            )}
          </ElementMenuContainer>
        ))}
      </div>
      <div>
        <BtnContainer close={sidebarClose}>
          {sidebarClose ? (
            <>
              {showTourButton && (
                <Button
                  className={`${tour_01.step_03}`}
                  onClick={() => setTourIsOpen(true)}
                  variant="outlinedSecondary"
                  sx={{
                    width: '100%',
                    marginTop: 1,
                    border: '1px solid',
                    '&:hover img': { filter: 'invert(1)' },
                  }}
                >
                  <img style={{ width: '14px' }} src={lightbulb} />
                </Button>
              )}
              <Button
                onClick={() => navigate(INFO_ASSISTANCE_PATHS.ROOT)}
                variant="outlinedSecondary"
                className={`${tour_01.step_04}`}
                sx={{
                  width: '100%',
                  border: '1px solid',
                  marginTop: 1,
                  fontSize: '20px',
                }}
              >
                ?
              </Button>
            </>
          ) : (
            <>
              <LogoContainer style={{ borderBottom: 0 }}>
                <Logo
                  style={{
                    margin: '16px 0',
                    width: '100%',
                    height: '100%',
                  }}
                  src={logoMaster}
                ></Logo>
              </LogoContainer>
              {showTourButton && (
                <Button
                  onClick={() => setTourIsOpen(true)}
                  className={`${tour_01.step_03}`}
                  variant="outlinedSecondary"
                  sx={{
                    width: '100%',
                    padding: '24px',
                    border: '1px solid',
                    marginTop: 1,
                  }}
                >
                  Guida Interattiva
                </Button>
              )}
              <Button
                onClick={() => navigate(INFO_ASSISTANCE_PATHS.ROOT)}
                className={`${tour_01.step_04}`}
                variant="outlinedSecondary"
                sx={{
                  width: '100%',
                  marginTop: 1,
                  border: '1px solid',
                  padding: '24px',
                }}
              >
                Info &amp; Assistenza
              </Button>
            </>
          )}
        </BtnContainer>
      </div>
    </SideBarContainer>
  );
};

export { Sidebar };

Sidebar.propTypes = {
  isInfoAssistance: PropTypes.bool,
  userType: PropTypes.string,
};
